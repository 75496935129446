import React, { useRef, useEffect, useMemo, useState, Fragment } from 'react';
import cn from 'classnames/bind';
import PropTypes from 'prop-types';

import { getOffset } from 'utils/offset';
import { SLIDER_COMPONENTS, getWrappedContent } from '../utils';

// Components
import Page from 'components/Page';
import Container from 'components/Container';
import FeaturedTopic from 'components/FeaturedTopic';
import PopularThisWeek from 'components/PopularThisWeek';
import AssetsSlider from 'components/AssetsSlider';
import ArticlesList from 'components/ArticlesList';
import ImagePromo from 'components/promos/ImagePromo';
import ArticlesWidget from 'components/widgets/Articles'; // TODO: Перенести в components/
import VendorsSlider from 'components/widgets/VendorsSlider'; // TODO: Перенести в components/
import PopularDiscussions from 'components/widgets/PopularDiscussions'; // TODO: Перенести в components/

// Custom Hooks
import useHomeArticlesSection from 'hooks/useHomeArticlesSection';

// Styles
import styles from './styles.styl';

const cx = cn.bind(styles);

const PROMOS_COUNT = 2;
const PROMO_HEIGHT = 260;

const CONTENT_COMPONENTS = {
  'featured-topic': FeaturedTopic,
  popular: PopularThisWeek,
  featured: AssetsSlider,
  ...SLIDER_COMPONENTS,
};

const CONTENT_WRAP_EXCEPTION = Object.keys(SLIDER_COMPONENTS);

const SIDEBAR_COMPONENTS = {
  trending: ArticlesWidget,
  vendors: VendorsSlider,
  discussions: PopularDiscussions,
};

function HomeContentDesktop({
  content,
  articles,
  sidebar,
  promos,
  page,
  isLoading,
  paginationHref,
  changePage,
}) {
  const contentRef = useRef();
  const [sidebarBouding, setSidebarBouding] = useState([]);
  const articlesSections = useHomeArticlesSection(articles.items, content); // Секции статей

  const lastSectionIndex = useMemo(
    () => articlesSections.reduce((acc, section) => (section.length ? acc + 1 : acc), -1),
    [articlesSections],
  );

  // Секции с баннерами
  const promosSections = useMemo(() => {
    let sectionIndex = 0;
    const promosSections = [];

    if (articlesSections.length) {
      for (let i = sectionIndex; i < PROMOS_COUNT; i++) {
        if (articlesSections[i]) {
          promosSections.push([]);
          sectionIndex = i;
        }

        promosSections[sectionIndex].push(
          <ImagePromo
            key={i}
            id={promos[i]}
            theme="home"
            gtmId={`Image_bner_Mid${sectionIndex + 1}`}
            className={cx('HomeContentDesktop__promo')}
          />,
        );
      }
    }

    return promosSections;
  }, [articlesSections, promos]);

  // Формирование секции сайтбара
  const sidebarSections = useMemo(() => {
    const articlesListCollection =
      contentRef.current && contentRef.current.querySelectorAll('[data-container="articles"]');
    const elementsInSection = []; // Индексы элементов в секциях

    return articlesSections.map((_item, index) => {
      // Если первый рендер для получения высоты
      if (!sidebarBouding.length) {
        return index === articlesSections.length - 1
          ? sidebar.slice(index, sidebar.length)
          : sidebar[index]
          ? [sidebar[index]]
          : [];
      }

      let sidebarHeight = index < PROMOS_COUNT ? PROMO_HEIGHT : 0;
      const section = [];

      sidebarBouding.forEach((elementSizes, elementIndex) => {
        if (
          !elementsInSection.includes(elementIndex) &&
          articlesListCollection[index].offsetHeight - sidebarHeight >= elementSizes.height
        ) {
          section.push(sidebar[elementIndex]);
          elementsInSection.push(elementIndex);
          sidebarHeight += elementSizes.height;
        }
      });

      return section;
    });
  }, [articlesSections, sidebarBouding, sidebar]);

  /** Элементы в footer.column
   * Элементы не поместившиеся в sidebar перемещаются
   *
   */
  const footerElements = useMemo(() => {
    return sidebar.reduce((acc, element) => {
      const Component = SIDEBAR_COMPONENTS[element.type];

      return Component &&
        (!articles.items.length || !sidebarSections.some(section => section.includes(element)))
        ? [
            ...acc,
            <Component
              key={element.id}
              className={cx('HomeContentDesktop__footerElement')}
              {...element.content}
            />,
          ]
        : acc;
    }, []);
  }, [articles.items.length, sidebar, sidebarSections]);

  // Наличие поля label в блоке со статьями
  const isExistLabel = useMemo(() => Boolean(articles.title) || Boolean(articles.image), [
    articles.image,
    articles.title,
  ]);

  // Эффект для определения высоты секции сайтбара
  useEffect(() => {
    const sidebarCollection = contentRef.current.querySelectorAll('[data-element="sidebar"]');

    setSidebarBouding(Array.from(sidebarCollection).map(element => getOffset(element)));
  }, [articlesSections]);

  return (
    <div ref={contentRef} className={cx('HomeContentDesktop')}>
      {!articlesSections.length &&
        content.map(({ content: contentSection, type }, index) => {
          const SectionComponent = contentSection && CONTENT_COMPONENTS[contentSection];

          if (!SectionComponent) return null;

          return getWrappedContent(
            !CONTENT_WRAP_EXCEPTION.includes(type),
            <Page.Section key={index} className={cx('HomeContentDesktop__section')}>
              <SectionComponent {...contentSection} />
            </Page.Section>,
          );
        })}

      {articlesSections.map((articleSection, index) => {
        const contentSection = content[index];
        const SectionComponent = contentSection && CONTENT_COMPONENTS[contentSection.type];

        return (
          <Fragment key={index}>
            {SectionComponent &&
              getWrappedContent(
                !CONTENT_WRAP_EXCEPTION.includes(contentSection.type),
                <Page.Section
                  key={index}
                  className={cx(
                    'HomeContentDesktop__section',
                    `HomeContentDesktop__section_${contentSection.type}`,
                  )}
                >
                  <SectionComponent {...contentSection.content} />
                </Page.Section>,
              )}
            {Boolean(articleSection.length) && (
              <Container>
                <Page.Section
                  className={cx(
                    'HomeContentDesktop__section',
                    'HomeContentDesktop__section_articles',
                  )}
                >
                  <div
                    data-anchor="articles"
                    data-container="articles"
                    className={cx('HomeContentDesktop__articles')}
                  >
                    <ArticlesList
                      isExistPagination={index === lastSectionIndex}
                      isLoading={isLoading}
                      title={articles.title}
                      image={articles.image}
                      page={page}
                      length={articles.total}
                      articles={articleSection}
                      paginationHref={paginationHref}
                      changePage={changePage}
                    />
                  </div>
                  <aside
                    className={cx('HomeContentDesktop__sidebar', {
                      ['HomeContentDesktop__sidebar_withLabel']: isExistLabel,
                    })}
                  >
                    {promosSections[index]}
                    {sidebarSections[index].map(element => {
                      const Component = SIDEBAR_COMPONENTS[element.type];

                      return <Component key={element.id} theme="inSidebar" {...element.content} />;
                    })}
                  </aside>
                </Page.Section>
              </Container>
            )}
          </Fragment>
        );
      })}

      {Boolean(footerElements.length) && (
        <Container>
          <Page.Section className={cx('HomeContentDesktop__footerSection')}>
            {footerElements}
          </Page.Section>
        </Container>
      )}
    </div>
  );
}

HomeContentDesktop.propTypes = {
  page: PropTypes.number.isRequired,
  articles: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  content: PropTypes.arrayOf(PropTypes.object),
  sidebar: PropTypes.arrayOf(PropTypes.object),
  promos: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool.isRequired,
  paginationHref: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
};

HomeContentDesktop.defaultProps = {
  content: [],
  sidebar: [],
  promos: [],
};

export default HomeContentDesktop;
