/* eslint-disable */
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Component
import ArticlePreview from 'components/ArticlePreview';
import ArticlePreviewSceleton from 'components/ArticlePreview/ArticlePreviewSceleton';
import Pagination from 'components/Pagination';
import ArticlesHeader from 'components/ArticlesHeader';
import ImagePromo from 'components/promos/ImagePromo';
import useAnalytics from 'hooks/useAnalytics';

import { barWidgetSelector } from 'state/customization/selectors';
// Styles
import cn from 'classnames/bind';
import styles from './styles.styl';

const cx = cn.bind(styles);

const ArticlesList = React.forwardRef(
  (
    {
      title,
      image,
      isLoading,
      isExistPagination,
      articles,
      page,
      length,
      url,
      assetsPath,
      tagsPath,
      changePage,
      paginationHref,
      className,
      promos,
      indexOfPromo,
    },
    forwardRef,
  ) => {
    const isExistHeader = useMemo(() => Boolean(title) || Boolean(image), [image, title]);
    const isPromo = typeof promos !== 'undefined' && promos.length > 0 && Boolean(promos);
    const { elementClick } = useAnalytics();
    const metasiteWidget = useSelector(barWidgetSelector);

    const goToBuyMetasite = () => {
      elementClick('80lv_site_metasites_click-cta', 'middle');
    };

    return (
      <div ref={forwardRef} className={cx('ArticlesList', className)}>
        {isExistHeader && (
          <ArticlesHeader className={cx('ArticlesList__header')} title={title} image={image} />
        )}

        <div className={cx('ArticlesList__group')}>
          <div
            className={cx('ArticlesList__articles', {
              ArticlesList__articles_show: !isLoading,
            })}
          >
            {articles.map((article, index) => {
              return (
                <Fragment key={article.id}>
                  {isPromo && index === indexOfPromo - 1 && (
                    <div className={cx('ArticlesList__promos')}>
                      <div className={cx('ArticlesList__promos-list')}>
                        {promos.map((promo, i) => (
                          <ImagePromo
                            key={promo}
                            gtmId={'Image_bner_Mid' + (i + 1)}
                            id={promo}
                            theme="light"
                            className={cx('ArticlesList__promos-item')}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  <div className={cx('ArticlesList__article')}>
                    <ArticlePreviewSceleton
                      className={cx('ArticlesList__skeleton', {
                        ArticlesList__skeleton_show: isLoading,
                      })}
                      inCategory
                      initRadius={false}
                    />
                    <ArticlePreview
                      className={cx('ArticlesList__article-preview', {
                        'ArticlesList__article-preview_show': !isLoading,
                      })}
                      inCategory
                      initRadius={false}
                      url={url}
                      assetsPath={assetsPath}
                      tagsPath={tagsPath}
                      {...article}
                    />
                  </div>
                  {index === 4 && metasiteWidget.isLoaded && (
                    <a
                      href={metasiteWidget.button.href}
                      style={{
                        '--metasite-bg-color': metasiteWidget.background_color,
                        '--metasite-text-color': metasiteWidget.color,
                        '--metasite-bg-image': `url(${metasiteWidget.background_image})`,
                        '--metasite-button-bg-color': metasiteWidget.button.background_color,
                        '--metasite-button-text-color': metasiteWidget.button.color,
                      }}
                      className={cx('ArticlesList__buyMetasite')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className={cx('ArticlesList__buyMetasite_description')}>
                        {metasiteWidget.text}
                      </div>
                      <div
                        className={cx('ArticlesList__buyMetasite_button')}
                        onClick={goToBuyMetasite}
                      >
                        <span className={cx('ArticlesList__buyMetasite_button_text')}>
                          {metasiteWidget.button.text}
                        </span>
                      </div>
                    </a>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>

        {isExistPagination && (
          <Pagination
            className={cx('ArticlesList__pagination')}
            currentNumber={page}
            length={length}
            setHref={paginationHref}
            onClick={changePage}
            showPrevButtonOnFirstPage={true}
          />
        )}
      </div>
    );
  },
);

ArticlesList.displayName = 'ArticlesList';

ArticlesList.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
  isExistPagination: PropTypes.bool,
  articles: PropTypes.array.isRequired,
  page: PropTypes.number,
  length: PropTypes.number,
  paginationHref: PropTypes.func,
  changePage: PropTypes.func,
  url: PropTypes.string,
  assetsPath: PropTypes.string,
  tagsPath: PropTypes.object,
  className: PropTypes.string,
  promos: PropTypes.array,
  indexOfPromo: PropTypes.number,
};

ArticlesList.defaultProps = {
  title: '',
  image: '',
  isLoading: false,
  isExistPagination: false,
  page: 0,
  length: 0,
  changePage: () => {},
  paginationHref: undefined,
  url: '/articles',
  assetsPath: null,
  className: '',
  indexOfPromo: 4,
};

export default ArticlesList;
