import React, { Fragment, useMemo } from 'react';
import cn from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import Page from 'components/Page';
import FeaturedTopic from 'components/FeaturedTopic';
import PopularThisWeek from 'components/PopularThisWeek';
import AssetsSlider from 'components/AssetsSlider';
import ArticlesList from 'components/ArticlesList';
import ImagePromo from 'components/promos/ImagePromo';
import ArticlesWidget from 'components/widgets/Articles'; // TODO: Перенести в components/
import Container from 'components/Container';
import VendorsSlider from 'components/widgets/VendorsSlider'; // TODO: Перенести в components/
import PopularDiscussions from 'components/widgets/PopularDiscussions'; // TODO: Перенести в components/

// Custom Hooks
import useHomeArticlesSection from 'hooks/useHomeArticlesSection';

import { SLIDER_COMPONENTS, getWrappedContent } from '../utils';

// Styles
import styles from './styles.styl';

const cx = cn.bind(styles);

const CONTENT_COMPONENTS = {
  'featured-topic': FeaturedTopic,
  popular: PopularThisWeek,
  featured: AssetsSlider,
  ...SLIDER_COMPONENTS,
};

const CONTENT_WRAP_EXCEPTION = Object.keys(SLIDER_COMPONENTS);

const FOOTER_COMPONENTS = {
  trending: ArticlesWidget,
  vendors: VendorsSlider,
  discussions: PopularDiscussions,
  image: ImagePromo,
};

function HomeContentTablet({
  content,
  articles,
  sidebar,
  promos,
  page,
  isLoading,
  paginationHref,
  changePage,
}) {
  const articlesSections = useHomeArticlesSection(articles.items, content); // Секции статей
  const footerComponents = useMemo(
    () => [...sidebar, { type: 'image', content: { id: promos[0], gtmId: 'Image_bner_Mid1' } }],
    [promos, sidebar],
  );

  const lastSectionIndex = useMemo(
    () => articlesSections.reduce((acc, section) => (section.length ? acc + 1 : acc), -1),
    [articlesSections],
  );

  return (
    <div className={cx('HomeContentTablet')}>
      {!articlesSections.length &&
        content.map(({ content: contentSection, type }, index) => {
          const SectionComponent = contentSection && CONTENT_COMPONENTS[contentSection];

          if (!SectionComponent) return null;

          return getWrappedContent(
            !CONTENT_WRAP_EXCEPTION.includes(type),
            <Page.Section key={index} className={cx('HomeContentTablet__section')}>
              <SectionComponent {...contentSection} />
            </Page.Section>,
          );
        })}

      {articlesSections.map((articleSection, index) => {
        const contentSection = content[index];
        const SectionComponent = contentSection && CONTENT_COMPONENTS[contentSection.type];

        return (
          <Fragment key={index}>
            {SectionComponent &&
              getWrappedContent(
                !CONTENT_WRAP_EXCEPTION.includes(contentSection.type),
                <Page.Section className={cx('HomeContentTablet__section')}>
                  <SectionComponent {...contentSection.content} />
                </Page.Section>,
              )}
            {Boolean(articleSection.length) && (
              <Container>
                <Page.Section className={cx('HomeContentTablet__section')}>
                  <ArticlesList
                    isExistPagination={index === lastSectionIndex}
                    isLoading={isLoading}
                    title={articles.title}
                    image={articles.image}
                    page={page}
                    length={articles.total}
                    articles={articleSection}
                    paginationHref={paginationHref}
                    changePage={changePage}
                  />
                </Page.Section>
              </Container>
            )}
          </Fragment>
        );
      })}

      <Container>
        <Page.Section
          className={cx('HomeContentTablet__section', 'HomeContentTablet__section_footer')}
        >
          {footerComponents.map(({ type, content: footerContent }, index) => {
            const FooterComponent = FOOTER_COMPONENTS[type];

            return (
              FooterComponent && (
                <div key={index} className={cx('HomeContentTablet__footerComponent')}>
                  <FooterComponent {...footerContent} />
                </div>
              )
            );
          })}
        </Page.Section>
      </Container>
    </div>
  );
}

HomeContentTablet.propTypes = {
  page: PropTypes.number.isRequired,
  articles: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  content: PropTypes.arrayOf(PropTypes.object),
  sidebar: PropTypes.arrayOf(PropTypes.object),
  promos: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool.isRequired,
  paginationHref: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
};

HomeContentTablet.defaultProps = {
  content: [],
  sidebar: [],
  promos: [],
};

export default HomeContentTablet;
